.button {
  display: inline-flex;
  justify-content: flex-start;
  text-transform: none;

  &:hover .icon {
    color: #747474
  }
}

.icon {
  font-size: 1.5em !important;
  margin-left: .25em;
  color: #c1c1c1;
}